<template>
  <div>
    <div>打开微信小程序唤起支付</div>
    <wx-open-launch-weapp id="launch-btn" username="gh_918c81628d6f" path="pages/index/index" env-version="trial">
      <template>
        <button style="width: 200px; height: 45px; text-align: center; font-size: 17px; display: block; margin: 0 auto; padding: 8px 24px; border: none; border-radius: 4px; background-color: #07c160; color:#fff;">打开小程序</button>
      </template>
    </wx-open-launch-weapp>
  </div>
</template>

<script>
  import wx from 'weixin-js-sdk';
  export default {
    name: "wechat_mini",
    created() {
      console.log(this.$route)
      console.log(wx)
      wx.config({
        debug: true,
        appId: 'wx956715dd137437bf',
        timestamp: 0,
        nonceStr: 'wechatPay',
        signature: 'signature',
        jsApiList: ['chooseImage'],
        openTagList:['wx-open-launch-weapp'],
      })
      wx.ready(() => {
        console.log('鉴权成功')
      })
    }
  }
</script>

<style scoped>

</style>
